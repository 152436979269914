<template>
  <footer>
    <ul>
      <li><router-link to="/modalites">Modalités de participation</router-link></li>
      <li><router-link to="/mentions">Mentions légales</router-link></li>
      <li><a :href="`https://oxoformdocs.fra1.digitaloceanspaces.com/${game_id}/reglement.pdf`" target="_blank">**Règlement du jeu</a></li>
      <li><router-link to="/contact">Contactez-nous</router-link></li>
      <li><a :href="`https://oxoformdocs.fra1.digitaloceanspaces.com/${game_id}/politique.pdf`" target="_blank">Politique de confidentialité</a></li>
    </ul>
  </footer>
</template>
<script>
var consts = require('../config');
export default {
    data() {
        return {
            game_id : consts.GAME_ID
        }
    }  
}
</script>
<template>
    <header>
    </header>
</template>
<script>
var consts = require('../config');


export default {
    name: 'HeaderElem',
    data() {
        return {
           
        }
    },
    components: {

    },
    methods: {   

    }    
}
</script>
<template>
  <main>
        <div class="counters">
      Il reste <div id="value"><span>0</span><span>0</span><span>0</span></div> participations disponibles
    </div> 

    <div class="form">
      <div class="modalites">
        <h2>Remplissez le formulaire ci-dessous :</h2>
      </div>
      <Form
        v-if="total > 0"
        :class="'form_step' + step"
        id="monform"
        :form_id="form_id"
        :server="server"
        v-on:results="getResult"
        v-on:step-up="checkStep"
        v-on:step-down="checkStep"
        v-on:form_submit_loading="loading"
      />
    </div>
    <div class="captcha">
      This site is protected by reCAPTCHA and the Google<br />
      <a href="https://policies.google.com/privacy" target="_blank"
        >Privacy Policy</a
      >
      and
      <a href="https://policies.google.com/terms" target="_blank"
        >Terms of Service</a
      >
      apply.
    </div>
  </main>
</template>
<script>
var consts = require("../config");
import axios from "axios";
export default {
  name: "home",
  data: function() {
    return {
      step: 0,
      total : 230,
      game_id: consts.GAME_ID,
      form_id: consts.FORM_ID,
      server: consts.SERVER_URL,
    };
  },
  methods: {
    animateValue : function (obj, start, end, duration) {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        var res = Math.floor(progress * (end - start) + start);
        var output = [],sNumber = res.toString();
    
        for (var i = 0, len = sNumber.length; i < len; i += 1) {
            output.push("<span>"+sNumber.charAt(i)+"</span>");
          }
        obj.innerHTML = output.join('');
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    },        
    gohome() {
      this.$router.push("/");
    },
    loading(value) {
      if (value === true) {
        this.$swal({
          allowOutsideClick: false,
          title: "Envoi en cours",
          html: "Merci de bien vouloir patienter ...",
          timerProgressBar: true,
          onBeforeOpen: () => {
            this.$swal.showLoading();
          },
        });
      }
    },
    checkStep() {
      this.step = this.step++;
    },
    getResult(result) {
      if (result._id) {
        window.sub_id = result._id + "|" + result.uid;
        this.$swal.close();
        this.$router.push("/done");
      } else {
        alert("Erreur interne");
      }
    },
  },
  async mounted() {
      try {
        let { data } = await axios.get("https://api.lucien.ai/api/v2/counters/6103ad547da1830015b527e8/Compteur");
        const obj = document.getElementById("value");
        this.total = data.value;
        const current_counter = data.value;
        var output = [],sNumber = current_counter.toString();
        for (var i = 0, len = sNumber.length; i < len; i += 1) {
            output.push("<span>"+sNumber.charAt(i)+"</span>");
          }
        obj.innerHTML = output.join('');
        this.animateValue(obj, 3000, data.value, 230);

      } catch(e) {
        console.log(e)
      }
  },
  created: function() {},
};
</script>